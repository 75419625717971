<template>
    
    <div class="h-auto rounded-lg bg-filters px-1 py-2 mb-2 flex flex-row">

        <div class="h-auto w-1 rounded-full mr-2" :style="'background-color:' + legendColors[data.id_sl].color + ';'"></div>
        
        <div class="flex-1">

            <div class="h-auto pb-3">
                <span class="text-xs text-white font-semibold">{{ data.name }}</span>
            </div>

            <div class="h-auto pb-3 flex flex-row justify-start items-center pr-1">
                <span class="text-3xs text-font-gray font-semibold mr-1">Sales</span>
                <span class="text-xxs text-white font-semibold mr-3">{{ data.total_sales | reduceBigNumbers(2) }}€</span>
                <span class="text-3xs text-font-gray mr-1">vs Obj</span>
                <span class="text-xxs mr-auto"
                      :class="successOrDangerClass(data.objective_sale,100)"
                ><span v-if="data.objective_sale>0">+</span>{{ data.objective_sale | reduceBigNumbers(2) }}<span v-if=" isNotNaAndTbd(data.objective_sale)">%</span></span>
                <span class="text-3xs text-font-gray mr-1">VSAvg (SL)</span>
                <span class="text-xxs"><span v-if=" data.avg_sale>0&&isNotNaAndTbd(data.avg_sale)">+</span>{{ data.avg_sale | numberFormat(2) }} <span v-if="isNotNaAndTbd(data.avg_sale)">ptos.</span></span>
            </div>

            <div class="h-auto pb-3 flex flex-row justify-start items-center pr-">
                <span class="text-3xs text-font-gray font-semibold mr-1">Sales Estimated</span>
                <span class="text-xxs text-white mr-auto">{{ data.estimated_sales | reduceBigNumbers(2) }}€</span>
                <span class="text-3xs text-font-gray font-semibold mr-1">vs Obj</span>
                <span class="text-xxs text-white"><span v-if="data.objective_estimated_sales>0">+</span>{{ data.objective_estimated_sales | numberFormat(2) }}<span v-if=" data.objective_estimated_sales!='TBD'">%</span></span>
            </div>

            <div class="h-auto flex flex-row justify-start items-center pr-2">
                <span class="text-3xs text-font-gray font-semibold mr-auto">Effectiveness ratio</span>
                <span class="text-xxs text-white font-semibold">{{ data.effectiveness_ratio | reduceBigNumbers(2) }}%</span>
            </div>

        </div>
    
    </div>

</template>

<script>
import { state  } from '@/store';

export default {
    name: 'PipelineSMUMobile',
    props: ['data', 'desktop'],
    computed: {
        legendColors() { return state.slLegendColors },
    }
}
</script>